@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");

body {
  background: rgb(41, 42, 53);
  padding-top: 10px;
  padding-bottom: 100px;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
}

.App {
  text-align: center;

  .MuiChip-root {
    margin: 0px 5px 5px 0px;
    border-radius: 5px;
    color: white;
  }

  .MuiDataGrid-root .MuiDataGrid-colCellTitleContainer {
    font-weight: 800;
    font-size: 12px;
  }

  .article-count {
    font-size: 12px;
    line-height: 24px;
    display: block;
    margin-right: 40px;
    color: rgb(143, 167, 197);
  }

  .MuiChip-root {
    cursor: pointer;
  }

  .MuiDataGrid-colCellWrapper {
    .MuiDataGrid-colCell {
      padding-right: 0px;
    }
  }
}

h1 {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  color: rgb(98, 115, 136);
  margin: 0 10px 0 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  &.sentiment-charts {
    border: 1px solid #7a8a9d;
    margin: 10px 0;
    border-radius: 5px;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.header-row {
  justify-content: space-between;
}

.col {
  justify-content: center;
  flex-grow: 1;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
