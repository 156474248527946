@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap);
.App {
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.col {
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loader{font-family:"Roboto Condensed",sans-serif;margin:auto;background:rgba(241,242,243,0) none repeat scroll 0% 0%;display:block;shape-rendering:auto;width:100px;height:100px}.loader path{fill:#5e7fa8}.loader text{text-transform:uppercase;font-weight:100;fill:#8bb9f1}.loader-dialog .MuiPaper-root{background:#45505d none repeat scroll 0% 0%}
#timeBrush{width:800px}.selection{fill:#404449;stroke:#657a94;stroke-width:.6px}.line{stroke:red;stroke-width:1px}
.footer-container{display:-webkit-flex;display:flex;-webkit-flex-grow:0;flex-grow:0;position:fixed;background:#1f1f28;width:100%;height:80px;bottom:0;text-align:left;left:0;border-top:1px solid #495666;-webkit-justify-content:center;justify-content:center}.footer-container .quick-date-filters{margin:6px 0 0 0}.footer-container .quick-date-filters button{margin-right:5px;margin-bottom:5px}
body{background:#292a35;padding-top:10px;padding-bottom:100px;color:#fff;font-family:"Roboto Condensed",sans-serif}.App{text-align:center}.App .MuiChip-root{margin:0px 5px 5px 0px;border-radius:5px;color:#fff}.App .MuiDataGrid-root .MuiDataGrid-colCellTitleContainer{font-weight:800;font-size:12px}.App .article-count{font-size:12px;line-height:24px;display:block;margin-right:40px;color:#8fa7c5}.App .MuiChip-root{cursor:pointer}.App .MuiDataGrid-colCellWrapper .MuiDataGrid-colCell{padding-right:0px}h1{font-size:16px;text-transform:uppercase;letter-spacing:1px;font-weight:600;color:#627388;margin:0 10px 0 0}.App-logo{height:40vmin;pointer-events:none}.row{display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;margin-bottom:10px}.row.sentiment-charts{border:1px solid #7a8a9d;margin:10px 0;border-radius:5px}.row.space-between{-webkit-justify-content:space-between;justify-content:space-between}.column{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column}.header-row{-webkit-justify-content:space-between;justify-content:space-between}.col{-webkit-justify-content:center;justify-content:center;-webkit-flex-grow:1;flex-grow:1}.col:not(:last-child){margin-right:10px}@media(prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.App-header{background-color:#282c34;min-height:100vh;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;font-size:calc(10px + 2vmin);color:#fff}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}
