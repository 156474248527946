
#timeBrush {
  width: 800px;
}

.selection {
  fill: #404449;
  stroke:#657a94;
  stroke-width: .6px;
}

.line {
  stroke: red;
  stroke-width: 1px;
}