.footer-container {
  display: flex;
  flex-grow: 0;
  position: fixed;
  background: #1f1f28;
  width: 100%;
  height: 80px;
  bottom: 0;
  text-align: left;
  left: 0;
  border-top: 1px solid #495666;
  justify-content: center;

  .quick-date-filters {
    margin: 6px 0 0 0;

    button {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}
