@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");



.loader {
  font-family: "Roboto Condensed", sans-serif;
  margin: auto;
  background: rgba(241, 242, 243, 0) none repeat scroll 0% 0%;
  display: block;
  shape-rendering: auto;
  width: 100px;
  height: 100px;

  path {
    fill: #5e7fa8;
  }

  text {
    text-transform: uppercase;
    font-weight: 100;
    fill: #8bb9f1;
  }
}

.loader-dialog {
  .MuiPaper-root {
    background: #45505d none repeat scroll 0% 0%;
  }
}